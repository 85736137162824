import * as React from 'react'
// material
import { styled } from '@mui/material/styles';
import { Grid, Paper, TextField, Button, Stack, Box} from '@mui/material';

// ----------------------------------------------------------------------
export default function UserDebugData({user, children}) {

  return (
    <>
      <Box>
        <pre>{JSON.stringify(user, undefined, 2)}</pre>
      </Box>
    </>
  );
}