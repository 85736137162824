import * as React from 'react'
// material
import { styled } from '@mui/material/styles';
import { Link } from 'gatsby-theme-material-ui';
// components
import Page from '../../components/Page';
import Settings from '../../components/settings';
import UserProfileEditor from '../../components/heroes/users/UserProfileEditor';
import UserDebugData from '../../components/heroes/users/UserDebugData';
import { Box, Button, Typography, Container, Stack, Avatar, Paper, Grid, Tabs, Tab } from '@mui/material';
import { login, isAuthenticated, getProfile } from "../../utils/auth"
import PersonIcon from '@mui/icons-material/Person';
import { motion } from 'framer-motion';
import { varFadeInUp, varWrapEnter, varFadeInDown } from '../../components/animate';
// router
import { Router } from "@reach/router"
// hooks
import { useAuth0 } from '@auth0/auth0-react';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11)
  }
}));

const RootContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));



const UserHome = (props) => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0();

  if (!isAuthenticated) {
      loginWithRedirect();
      return (<p>Redirecting to login...</p>)
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  const handleTabChange = (event, newValue) => {
  };

  return (
   <RootStyle title="User">
     <motion.div initial="initial" animate="animate" variants={varWrapEnter}>
      <RootContainer>
        <Container maxWidth="lg">

        <motion.div variants={varFadeInDown}>
          <Stack direction="row" spacing={2} sx={{mb: 2}}>
            <Avatar alt={user.given_name} src={user.picture} sx={{ width: 56, height: 56, mr: 1 }}/>
            <Typography variant="h4" gutterBottom component="div" align="center">{user.given_name} {user.family_name}</Typography>
            {/*<>Hello, {user.given_name}{' '}!</>  */}
          </Stack>
        </motion.div>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={props.location.pathname} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label="Profile" value="/user" to="/user" component={Link} />
              <Tab label="Debug" value="/user/debug" to="/user/debug" component={Link}/>
            </Tabs>  

          {/*<nav>
              <Link to="/user">Profile</Link>{" "}
              <Link to="/user/debug">Debug</Link>{" "}
            </nav>*/}
          </Box>

            <Router>
              <UserProfileEditor path="/user" user={user} />
              <UserDebugData path="/user/debug" user={user} />
            </Router>

          </Container>
        </RootContainer>
      </motion.div>
  </RootStyle>
  )
}

export default UserHome