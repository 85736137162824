import * as React from 'react'
// material
import { styled } from '@mui/material/styles';
import { Grid, Paper, TextField, Button, Stack, Box} from '@mui/material';
// react form
import { useForm, Controller } from 'react-hook-form';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------
export default function UserProfileEditor({user, children}) {
  const { handleSubmit, control } = useForm();
  const onSubmit = (data) => console.log(data);
  const onCancel = () => console.log("Cancel");

  return (
    <>
      <Box sx={{ m: 2 }} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Controller
            name="firstName"
            control={control}
            defaultValue={user.given_name}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="First Name"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{ required: 'First name required' }}
          />
          <Controller
              name="lastName"
              control={control}
              defaultValue={user.family_name}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Last Name"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              rules={{ required: 'Last name required' }}
            />
            <Controller
              name="email"
              control={control}
              defaultValue={user.email}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Email"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="email"
                  disabled
                />
              )}
              rules={{ required: 'Email required' }}
            />
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              {/*<Button variant="outlined" onClick={onCancel}>Cancel</Button>*/}
              <Button type="submit" variant="contained" color="primary">Save</Button>
            </Stack>
        </Stack>
      </form>
    </>
  );
}